import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TabDescriptor, TabRoutingComponent } from '@hs/ui-core-layout';
import { NavBarComponent } from '@hs/ui-core-navbar';

import { VersionService } from '../../../version.service';
import { UserEntitlements } from '../../models/entitlements';
import { EntitlementsService } from '../../services/entitlements.service';

@Component({
    selector: 'app-nav-wrapper',
    templateUrl: './nav-wrapper.component.html',
    styleUrls: ['./nav-wrapper.component.scss'],
    standalone: true,
    imports: [
        NavBarComponent,
        TabRoutingComponent,
        RouterOutlet,
    ],
})
export class NavWrapperComponent implements OnChanges, OnInit {
    @Input() clientCode: string | undefined;

    protected version: string = '';
    protected tabDescriptors: TabDescriptor[] = [];

    constructor(
        private readonly entitlementsService: EntitlementsService,
        private readonly versionService: VersionService,
    ) {}

    ngOnInit(): void {
        this.versionService.getVersion()
            .subscribe({
                next: (v) => {
                    this.version = v;
                },
            });
    }

    ngOnChanges(): void {
        if (!this.clientCode) {
            return;
        }

        this.entitlementsService.getEntitlements(this.clientCode)
            .subscribe({
                next: (userEntitlements) => this.setTabDescriptors(userEntitlements),
            });
    }

    private setTabDescriptors(userEntitlements: UserEntitlements): void {
        // CC doesn't work when reassigning an array,
        // so we need to empty the one that we provide initially
        while (this.tabDescriptors.length) {
            this.tabDescriptors.pop();
        }

        this.tabDescriptors.push({ value: 'queries/new', text: 'QUERY MANAGER' });

        if (userEntitlements.canManageCustomFields) {
            this.tabDescriptors.push({ value: 'fields/new', text: 'FIELD BUILDER' });
        }
    }
}
