import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { map, Observable } from 'rxjs';

import { EntitlementsService } from '../../services/entitlements.service';

export const canAccessFieldBuilderGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
    const entitlementsService = inject(EntitlementsService);
    const clientCode = route.params['clientCode'];

    return entitlementsService.getEntitlements(clientCode)
        .pipe(
            map((entitlements) => entitlements.canManageCustomFields),
        );
};
