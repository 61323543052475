import { NgModule } from '@angular/core';
import {
    provideRouter,
    Routes,
    withComponentInputBinding,
    withRouterConfig,
} from '@angular/router';
import { AdvancedDatagridModule } from '@hs/ui-core-datagrid-advanced';
import { WINDOW } from '@hs/ui-core-presentation';

import { NavWrapperComponent } from './shared/components/nav-wrapper/nav-wrapper.component';
import { canAccessFieldBuilderGuard } from './shared/guards/can-access-field-builder/can-access-field-builder.guard';
import { canAccessNamedQueryGuard } from './shared/guards/can-access-named-query/can-access-named-query.guard';
import { WINDOW_TOKEN } from './shared/services/entitlements.service';

export const routes: Routes = [
    { path: ':clientCode', pathMatch: 'full', redirectTo: ':clientCode/queries' },
    {
        path: ':clientCode',
        component: NavWrapperComponent,
        children: [
            {
                path: 'queries',
                loadChildren: async () => import('./named-query/routes').then((m) => m.routes),
                canActivate: [canAccessNamedQueryGuard],
            },
            {
                path: 'fields',
                loadChildren: async () => import('./field-builder/routes').then((m) => m.routes),
                canActivate: [canAccessNamedQueryGuard, canAccessFieldBuilderGuard],
            },
        ],
    },
];

@NgModule({
    imports: [
        AdvancedDatagridModule,
    ],
    providers: [
        { provide: WINDOW_TOKEN, useValue: window },
        { provide: WINDOW, useValue: window },
        provideRouter(
            routes,
            withComponentInputBinding(),
            withRouterConfig({ paramsInheritanceStrategy: 'always' }),
        ),
    ],
})
export class AppRoutingModule {}
