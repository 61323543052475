<cc-nav-bar
    [multiClientApp]="false"
    [appDisplayName]="'NQS'"
    [clientCode]="clientCode"
    [versions]="{ version }"
>
</cc-nav-bar>

<div class="app-content-wrapper">
    <cc-tab-routing level="main" [tabDescriptors]="tabDescriptors"></cc-tab-routing>
    <router-outlet></router-outlet>
</div>
